import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@/layout"
import {
  Box,
  CTA,
  SEO,
  Width,
  Heading,
  Text,
  SmallWave,
  MentorAlert,
  ArrowAlt,
  CalComplete,
  CalIncomplete,
  PromoBanner,
  Relationships,
  Grow,
  MentorCard,
} from "@/components/index"
import { color } from "@/theme/index"

export default function Mentorship({ data }) {
  // let nextMonday = new Date()
  // nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7))
  // const nextMondayDate =
  //   nextMonday.getMonth() +
  //   1 +
  //   "/" +
  //   nextMonday.getDate() +
  //   "/" +
  //   nextMonday.getFullYear()
  return (
    <Layout headerType="simple" headerTheme="light" footerTheme="light">
      <SEO
        title="Sonora’s Guitar Mentorship Programs"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <Box
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        pt="9.6rem"
      >
        {/* Accelerated Learning */}
        <Box variant="slopeBottomLeft">
          <Width pt="8rem" pb="6rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 6 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading mb="3.6rem">
                Accelerate your growth with Sonora Mentorship
              </Heading>
              <Text mt="3.6rem">
                Sonora's Mentorship Program provides 1-on-1 access to the
                world’s top guitarists and educators, weekly Masterclasses,
                facilitated practice sessions, and more.
              </Text>
            </Box>
            <Box display={["block", null, "flex", null]}>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                borderRadius=".6rem"
                textAlign="center"
                mb={["2.4rem", null, "0"]}
                bg="#FFF7F4"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="1.8rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <Grow mr="0.8rem" height={30} width={30} />
                  <Text fontWeight="bold" color="amethyst">
                    Grow with the pros
                  </Text>
                </Box>
                <Text level={2}>
                  Our mentors are acclaimed guitarists and seasoned educators
                  who can help students break through plateaus.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                textAlign="center"
                mb={["2.4rem", null, "0"]}
                bg={color.opal}
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  display="flex"
                  mb="1.8rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <Relationships height={30} width={38} mr="0.5rem" />
                  <Text fontWeight="bold" color="amethyst">
                    Build relationships
                  </Text>
                </Box>
                <Text level={2}>
                  Choose a mentor that fits your style, and develop a meaningful
                  relationship as you accelerate your musicianship.
                </Text>
              </Box>
              <Box
                width={["100%", "80%", 4 / 12, null]}
                p="2.4rem"
                textAlign="center"
                bg={color.agate}
                borderRadius=".6rem"
                ml={["auto", null, 0, null]}
                mr={["auto", null, 0, null]}
              >
                <Box
                  display="flex"
                  mb="0.6rem"
                  alignItems="center"
                  justifyContent={["center", null, null]}
                >
                  <MentorAlert mr="0.8rem" height={40} width={41} />
                  <Text fontWeight="bold" color="amethyst">
                    Get unlimited feedback
                  </Text>
                </Box>
                <Text level={2}>
                  No waiting for your weekly lesson. Ask questions and get
                  detailed feedback from your mentor when you need it.
                </Text>
              </Box>
            </Box>
            <Box width="100%" mt="3.6rem" mx="auto" textAlign="center">
              {/* <Text level={2}>
                <strong>Upcoming Cohort</strong>
                <br />
                <Heading
                  mb=".8rem"
                  mx={["auto", 0, null, null]}
                  color={color.tanzanite}
                  textAlign="center"
                >
                  {nextMondayDate}
                </Heading>
              </Text> */}
              <CTA to="/application" mt="3.6rem">
                Apply Now
              </CTA>
            </Box>
          </Width>
        </Box>

        {/* Mentor Section One */}
        <Box
          bg={color.opal_hover}
          variant="slopeTopAndBottom"
          mt="-3.6rem"
          pb="6rem"
        >
          <Heading textAlign="center" py="3.6rem">
            Full Time Faculty
          </Heading>
          <Width>
            <Box
              display="grid"
              gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
              gridGap={["3.8rem", null, "6rem 2rem", null]}
              mx="auto"
              width={["100%", "80%", 10 / 12, null]}
            >
              {/* Individual Mentors */}
              <MentorCard
                name="Brian Green"
                slug="brian-green"
                image={data.brianGreenImage.childImageSharp.fluid}
              >
                Brian Green is a guitar player and producer based out of Los
                Angeles, California. He has toured, performed and/or recorded
                with artists including John Legend, Rufus Wainwright, Michael
                Bublé, Nayo, and Kat Mcphee.
              </MentorCard>
              <MentorCard
                name="Robb Cappelletto"
                slug="robb-cappelletto"
                image={data.robbCappellettoImage.childImageSharp.fluid}
              >
                Robb Cappelletto is a guitarist, composer and educator based in
                Toronto, Canada. He has two decades of professional teaching
                experience and has been on faculty at York University as a jazz
                guitar instructor since 2007.
              </MentorCard>
              <MentorCard
                name="Curt Henderson"
                slug="curt-henderson"
                image={data.curtHendersonImage.childImageSharp.fluid}
              >
                Nashville-based Curt Henderson has played, recorded, and toured
                internationally with major artists like EZA (Ellery Bonham), and
                John King, and has worked with American Idol finalist Jonny
                Brenns.
              </MentorCard>
              <MentorCard
                name="Seth Rosenbloom"
                image={data.sethRosenbloommImage.childImageSharp.fluid}
              >
                A highly regarded blues guitarist, Seth has shared the stage
                with legendary musicians and played at almost every iconic club
                across the US. He has developed a unique approach to teaching
                blues that melds generations of styles into a modern, adaptable
                technique.
              </MentorCard>

              <MentorCard
                name="Trevor Giancola"
                image={data.trevorGiancolaImage.childImageSharp.fluid}
              >
                Toronto-based Guitarist and Educator Trevor Giancola has shared
                the stage with legendary musicians and built a strong reputation
                for himself teaching at the university level for over a decade.
              </MentorCard>
              <MentorCard
                name="Mike Chisnall"
                image={data.mikeChisnallImage.childImageSharp.fluid}
              >
                Mike Chisnall is an LA based, British guitarist. He has spent
                the last decade touring and performing as a hired gun, being a
                musical director and sideman. Most notably with the YouTube
                sensation Postmodern Jukebox, where Mike has toured, recorded,
                and filmed videos with them that have amassed millions of views
                worldwide.
              </MentorCard>
              <MentorCard
                name="Rob Garland"
                image={data.robGarlandImage.childImageSharp.fluid}
              >
                Rob Garland, originally from England, played with 'The Blue
                Monks' in the U.S., opening for legends like B.B. King. Now
                based in Los Angeles, he performs at renowned venues and has
                recorded at top studios, including J.J. Abrams’ Bad Robot. With
                over 25 years of guitar teaching experience, Rob has taught
                countless students, been a faculty member at Musicians
                Institute, and developed courses for TrueFire & JamPlay. His
                bestseller includes 'Chord Navigator: CAGED'. In 2023, he
                authored 'The CAGED Rhythm Guitar Method'. He's collaborated
                with musicians like Steve Vai and is endorsed by brands like
                Bogner amps.
              </MentorCard>
              <MentorCard
                name="Matt Sickels"
                image={data.mattSickelsImage.childImageSharp.fluid}
              >
                Matt Sickels, based in Greensboro, NC, began his musical journey
                in Ohio and honed his guitar skills at the distinguished
                Cuyahoga Community College's Jazz Studies Program. Further
                refining his expertise at the Atlanta Institute of Music and
                Media, he was mentored by acclaimed instructors and had sessions
                with guitar maestros like Guthrie Govan. Today, with vast
                performance and teaching experience, Matt offers private guitar
                and theory lessons, blending rich tradition with modern
                techniques.
              </MentorCard>
              <MentorCard
                name="Michael Wynar"
                image={data.michaelWynarImage.childImageSharp.fluid}
              >
                Michael Wynar, a prominent Bay Area musician and guitar
                instructor, honed his skills in jazz at Northern Illinois
                University. Since moving to the Bay Area in 2006, he's
                established a significant musical footprint: teaching,
                initiating the GrooveLab after-school program, and performing at
                iconic venues like The Great American Music Hall and Fox
                Theater. He's toured with Grammy-winner Jody Watley and
                collaborated with legends like George Clinton. Michael is a
                member of Oakland's Strike Iron band and actively collaborates
                with funk band Sal’s Greenhouse. His diverse expertise spans
                gospel to jazz and summer festivals.
              </MentorCard>
              
              {/* <MentorCard
                name="Francois Chanvallon"
                image={data.francoisChanvallonImage.childImageSharp.fluid}
              >
                A highly regarded blues guitarist, Seth has shared the stage
                with legendary musicians and played at almost every iconic club
                across the US. He has developed a unique approach to teaching
                blues that melds generations of styles into a modern, adaptable
                technique.
              </MentorCard> */}
            </Box>
          </Width>
        </Box>

        {/* Modern Study */}
        <Box variant="slopeTopRight" mt="-3.6rem">
          <Width pt="3.6rem" pb="6rem">
            <Box
              width={["100%", 10 / 12, 8 / 12, 6 / 12]}
              mx="auto"
              mb="5rem"
              textAlign="center"
            >
              <SmallWave display="block" mx="auto" mb="1.2rem" />
              <Heading level="2" mb="2rem">
                A modern approach to study
              </Heading>
              <Text>
                Unlike private lessons, Sonora’s mentored students get unlimited
                access to feedback so learning and progress can happen every day
                – not just once a week.
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection={["column", null, "row", null]}
              mx="auto"
            >
              <Box
                width={["100%", 10 / 12, 5 / 12, null]}
                textAlign="center"
                mx="auto"
              >
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  color={color.tanzanite}
                  mb=".6rem"
                >
                  THE OLD WAY
                </Text>
                <Text as="h3" color="amethyst" mb="0.6rem">
                  Traditional lessons
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  pt=".6rem"
                  pb="3rem"
                >
                  <CalComplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                  <CalIncomplete />
                </Box>
                <Text level="2">
                  Students met with an instructor once a week. For the remainer
                  of that week, students were more likely to practice bad habits
                  and progress slowly.
                </Text>
              </Box>
              <Box
                width={["100%", null, 2 / 12, null]}
                display="flex"
                alignItems="center"
                mx={["0", null, "3.6rem", null]}
                mt={["3.6rem", null, 0, null]}
                mb={["2.4rem", null, "0", null]}
              >
                <ArrowAlt
                  // eslint-disable-next-line
                  transform={["rotate(90deg)", null, "rotate(0deg)"]}
                  mx="auto"
                  stroke="#B3A8CB"
                />
              </Box>
              <Box
                width={["100%", 10 / 12, 5 / 12, null]}
                textAlign="center"
                mx="auto"
              >
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  color={color.tanzanite}
                  mb=".6rem"
                >
                  THE MODERN WAY
                </Text>
                <Text as="h3" color="amethyst" mb="0.6rem">
                  Sonora's anytime framework
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  pt=".6rem"
                  pb="3rem"
                >
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                  <CalComplete />
                </Box>
                <Text level="2">
                  Students get unlimited access to a mentor, 7 days a week.
                  Along with a custom daily practice plan, forward progress can
                  happen rapidly and at any time.
                </Text>
              </Box>
            </Box>
            <Box width="100%" mx="auto" textAlign="center">
              <CTA to="/application" variation="primary" mt="3.6rem">
                Apply Now
              </CTA>
            </Box>
          </Width>
        </Box>

        {/* Mentor Section Two */}
        <Box
          bg={color.opal_hover}
          variant="slopeTopAndBottomAlt"
          mt="-3.6rem"
          mb="-3.6rem"
          pb="6rem"
        >
          <Heading textAlign="center" py="3.6rem">
            Guest Lecturers
          </Heading>
          <Width pt="1.8rem">
            <Box
              display="grid"
              gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
              gridGap={["3.8rem", null, "6rem 2rem", null]}
              mx="auto"
              width={["100%", "80%", 10 / 12, null]}
            >
              {/* Individual Mentors */}
              <MentorCard
                name="Mark Whitfield"
                image={data.markWhitfieldImage.childImageSharp.fluid}
              >
                Mark Whitfield is a Berklee-trained jazz guitarist who has
                collaborated with legendary artists including Dizzy Gillespie,
                Art Blakey, Quincy Jones, Ray Charles, Herbie Hancock, and more.
              </MentorCard>

              <MentorCard
                name="Josh Sklair"
                image={data.joshSklairImage.childImageSharp.fluid}
              >
                Josh Sklair is a two-time Grammy Award winner, honored during
                his 20+ years collaboration as band leader, guitarist, arranger
                and co-writer with American Music icon Etta James.
              </MentorCard>
              <MentorCard
                name="Adam Levy"
                image={data.adamLevyImage.childImageSharp.fluid}
              >
                Los Angeles-based Adam Levy has released more than a dozen
                albums of his own, and is featured on recordings by Norah Jones,
                Vulfpeck, Ani DiFranco, Allen Toussaint, and other renowned
                artists.
              </MentorCard>
              <MentorCard
                name="Steve Cardenas"
                image={data.steveCardenasImage.childImageSharp.fluid}
              >
                Brooklyn-based jazz guitarist Steve Cardenas has recorded and
                toured with such jazz greats as Paul Motian, Charlie Haden and
                Steve Swallow, to name a few. Steve is also on faculty at The
                New School for Jazz and Contemporary Music in New York City.
              </MentorCard>
              <MentorCard
                name="Amanda Monaco"
                image={data.amandaMonacoImage.childImageSharp.fluid}
              >
                Amanda is an Associate Professor at Berklee College of Music
                where she teaches private instruction, labs and guitar
                ensembles. and has performed with artists such as Milt Hinton,
                Steve Wilson, Rufus Reid, and more.
              </MentorCard>

              <MentorCard
                name="Arianna Powell"
                image={data.ariannaPowellImage.childImageSharp.fluid}
              >
                Arianna Powell is a Los Angeles-based touring and session
                guitarist. She currently plays guitar for Halsey and has also
                toured with Nick Jonas, Black Eyed Peas, JJ Lin, Drake Bell, and
                more.
              </MentorCard>
              <MentorCard
                name="Mimi Fox"
                slug="mimi-fox"
                image={data.mimiFoxImage.childImageSharp.fluid}
              >
                Internationally renowned guitarist, composer, and recording
                artist Mimi Fox has worked with artists Abbey Lincoln, Joey
                DeFrancesco, Charlie Byrd, Branford Marsalis, Diana Krall, and
                Stevie Wonder, and more.
              </MentorCard>
              <MentorCard
                name="Jordan Peters"
                image={data.jordanPetersImage.childImageSharp.fluid}
              >
                Brooklyn based guitarist and producer, Jordan Peters has worked,
                toured, performed and recorded with artists such as Lauryn Hill,
                Renée Elise Goldsberry, Jon Batiste, The Weeknd, NAS, Billy
                Porter, Quiñ, The Roots, and more.
              </MentorCard>

              <MentorCard
                name="Nicholas Veinoglou"
                image={data.nicholasVeinoglouImage.childImageSharp.fluid}
              >
                Nicholas is an LA-based session and touring musician who has
                toured both nationally and internationally with artists like
                Jordan Fisher and Atlantic Records recording artist Bazzi.
              </MentorCard>
              <MentorCard
                name="Nora Bite"
                image={data.noraBiteImage.childImageSharp.fluid}
              >
                Jazz guitarist Nora Bite is a London-based educator and composer
                from Latvia. One of the many artists Nora has had the
                opportunity to share the stage with includes the great, late Amy
                Winehouse.
              </MentorCard>
              <MentorCard
                name="TJ Whitelaw"
                image={data.tjWhitelawImage.childImageSharp.fluid}
              >
                Tj is a Toronto-based touring and session guitarist. His
                on-stage experience is wide-ranging, spanning from Pop/country
                to jazz to Gospel. He’s performed with MYA, Jessie Reyez,
                Madison Beer, Kardinal Offishall, and more.
              </MentorCard>
              <MentorCard
                name="Alicyn Yaffee"
                image={data.alicynYaffeeImage.childImageSharp.fluid}
              >
                Alicyn Yaffee is a New York City-based guitarist, vocalist and
                bassist, She has toured the U.S. and Europe under her own name,
                as well as with Becca Stevens, Grammy-nominee JC Hopkins’
                Biggish Band, and bassist Benny Rietveld (Santana).
              </MentorCard>
            </Box>
          </Width>
        </Box>

        {/* Promotional Banner */}
        <PromoBanner />
      </Box>
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    adamLevyImage: file(relativePath: { eq: "adamLevy.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    robGarlandImage: file(relativePath: { eq: "robGarland.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mikeChisnallImage: file(relativePath: { eq: "mikeChisnall.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    francoisChanvallonImage: file(
      relativePath: { eq: "francoisChanvallon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mattSickelsImage: file(relativePath: { eq: "mattSickels.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    sethRosenbloommImage: file(relativePath: { eq: "sethRosenbloom.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    michaelWynarImage: file(relativePath: { eq: "michaelWynar.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    trevorGiancolaImage: file(relativePath: { eq: "trevorGiancola.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    amandaMonacoImage: file(relativePath: { eq: "amandaMonaco.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ariannaPowellImage: file(relativePath: { eq: "ariannaPowell.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    beccaStevensImage: file(relativePath: { eq: "beccaStevens.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    brianGreenImage: file(relativePath: { eq: "brianGreen.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curtHendersonImage: file(relativePath: { eq: "curtHenderson.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    horaceBrayImage: file(relativePath: { eq: "horaceBray.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jeffParkerImage: file(relativePath: { eq: "jeffParker.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    jordanPetersImage: file(relativePath: { eq: "jordanPeters.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    joshSklairImage: file(relativePath: { eq: "joshSklair.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markWhitfieldImage: file(relativePath: { eq: "markWhitfield.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mimiFoxImage: file(relativePath: { eq: "mimiFox.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mollyMillerImage: file(relativePath: { eq: "mollyMiller.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    nicholasVeinoglouImage: file(
      relativePath: { eq: "nicholasVeinoglou.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    noraBiteImage: file(relativePath: { eq: "noraBite.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    robbCappellettoImage: file(relativePath: { eq: "robbCappelletto.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    rotemSivanImage: file(relativePath: { eq: "rotemSivan.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ryanLermanImage: file(relativePath: { eq: "ryanLerman.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    steveCardenasImage: file(relativePath: { eq: "steveCardenas.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    tjWhitelawImage: file(relativePath: { eq: "tjWhitelaw.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    alicynYaffeeImage: file(relativePath: { eq: "alicynYaffee.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
